import revive_payload_client_xTWJbjlgji from "C:/Users/jackb/Documents/Development/secret-stats/node_modules/.pnpm/nuxt@3.10.3_@libsql+client@0.4.3_@types+node@20.11.25_better-sqlite3@9.2.2_drizzle-orm@0.29.5_op577wbuamuvdssveh6i7z4euy/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_RDWgcVDm3E from "C:/Users/jackb/Documents/Development/secret-stats/node_modules/.pnpm/nuxt@3.10.3_@libsql+client@0.4.3_@types+node@20.11.25_better-sqlite3@9.2.2_drizzle-orm@0.29.5_op577wbuamuvdssveh6i7z4euy/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_MFIUgRQAnn from "C:/Users/jackb/Documents/Development/secret-stats/node_modules/.pnpm/nuxt@3.10.3_@libsql+client@0.4.3_@types+node@20.11.25_better-sqlite3@9.2.2_drizzle-orm@0.29.5_op577wbuamuvdssveh6i7z4euy/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_6SgJ4PWIoQ from "C:/Users/jackb/Documents/Development/secret-stats/node_modules/.pnpm/nuxt@3.10.3_@libsql+client@0.4.3_@types+node@20.11.25_better-sqlite3@9.2.2_drizzle-orm@0.29.5_op577wbuamuvdssveh6i7z4euy/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_wOmCnyNMrW from "C:/Users/jackb/Documents/Development/secret-stats/node_modules/.pnpm/nuxt@3.10.3_@libsql+client@0.4.3_@types+node@20.11.25_better-sqlite3@9.2.2_drizzle-orm@0.29.5_op577wbuamuvdssveh6i7z4euy/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_GnNSCtQ5yt from "C:/Users/jackb/Documents/Development/secret-stats/node_modules/.pnpm/@pinia+nuxt@0.5.1_typescript@5.3.3_vue@3.4.21/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "C:/Users/jackb/Documents/Development/secret-stats/apps/frontend-bee/.nuxt/components.plugin.mjs";
import prefetch_client_FRtbIs2Oqb from "C:/Users/jackb/Documents/Development/secret-stats/node_modules/.pnpm/nuxt@3.10.3_@libsql+client@0.4.3_@types+node@20.11.25_better-sqlite3@9.2.2_drizzle-orm@0.29.5_op577wbuamuvdssveh6i7z4euy/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import unocss_MzCDxu9LMj from "C:/Users/jackb/Documents/Development/secret-stats/apps/frontend-bee/.nuxt/unocss.mjs";
import __typed_router_plugin_qqlMBmQBVV from "C:/Users/jackb/Documents/Development/secret-stats/apps/frontend-bee/.nuxt/__typed-router.plugin.ts";
import chunk_reload_client_KHHoy4jAAo from "C:/Users/jackb/Documents/Development/secret-stats/node_modules/.pnpm/nuxt@3.10.3_@libsql+client@0.4.3_@types+node@20.11.25_better-sqlite3@9.2.2_drizzle-orm@0.29.5_op577wbuamuvdssveh6i7z4euy/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
export default [
  revive_payload_client_xTWJbjlgji,
  unhead_RDWgcVDm3E,
  router_MFIUgRQAnn,
  payload_client_6SgJ4PWIoQ,
  check_outdated_build_client_wOmCnyNMrW,
  plugin_vue3_GnNSCtQ5yt,
  components_plugin_KR1HBZs4kY,
  prefetch_client_FRtbIs2Oqb,
  unocss_MzCDxu9LMj,
  __typed_router_plugin_qqlMBmQBVV,
  chunk_reload_client_KHHoy4jAAo
]