import { default as indexTzsioenLtPMeta } from "C:/Users/jackb/Documents/Development/secret-stats/apps/frontend-bee/src/pages/index.vue?macro=true";
export default [
  {
    name: indexTzsioenLtPMeta?.name ?? "index",
    path: indexTzsioenLtPMeta?.path ?? "/",
    meta: indexTzsioenLtPMeta || {},
    alias: indexTzsioenLtPMeta?.alias || [],
    redirect: indexTzsioenLtPMeta?.redirect,
    component: () => import("C:/Users/jackb/Documents/Development/secret-stats/apps/frontend-bee/src/pages/index.vue").then(m => m.default || m)
  }
]