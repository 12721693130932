import { type IR6ConfigEntity, getR6Config } from "@secret-stats/r6-api";

export interface SiegeConfig extends IR6ConfigEntity {
	rankMapByMinPoints: Record<number, string>;
}

export function useSiegeConfig(): SiegeConfig {
	const nuxtApp = useNuxtApp();
	const siegeConfig = nuxtApp.payload.data?.siegeConfig as SiegeConfig;
	if (!siegeConfig) {
		throw new Error("No siegeConfig detected on page");
	}

	// TODO remove from SSR payload
	siegeConfig.rankMapByMinPoints = Object.values(siegeConfig.ranks).reduce(
		(last, curr) => {
			last[curr.minPoints ?? 0] = curr.slug;
			return last;
		},
		{} as SiegeConfig["rankMapByMinPoints"],
	);

	return siegeConfig;
}

export async function fetchSiegeConfig() {
	await useAsyncData("siegeConfig", async () => {
		const { data } = await getR6Config();
		return data;
	});
}
