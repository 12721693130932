var __defProp = Object.defineProperty;
var __name = (target, value) => __defProp(target, "name", { value, configurable: true });

// src/api/axios.ts
import xior, { merge } from "xior";
var API_INSTANCE = xior.create({
  baseURL: typeof process !== "undefined" ? process.env.BACKEND_DOMAIN : (
    // @ts-expect-error
    typeof import.meta.env !== "undefined" ? (
      // @ts-expect-error
      import.meta.env.BACKEND_DOMAIN
    ) : void 0
  ),
  headers: {
    Accept: "application/json",
    "Accept-Encoding": "gzip, deflate, br"
  }
});
API_INSTANCE.interceptors.request.use((config) => {
  return merge(config, {
    headers: {
      "X-API-Key": uuidv4()
    }
  });
});
function setR6BaseUrl(baseURL) {
  API_INSTANCE.defaults.baseURL = baseURL;
}
__name(setR6BaseUrl, "setR6BaseUrl");
function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
    const r = Math.random() * 16 | 0, v = c === "x" ? r : r & 3 | 8;
    return v.toString(16);
  });
}
__name(uuidv4, "uuidv4");

// src/api/getR6BansData.ts
async function getR6BansData() {
  return API_INSTANCE.get(`/bans`);
}
__name(getR6BansData, "getR6BansData");

// src/api/getR6Config.ts
async function getR6Config() {
  return API_INSTANCE.get("/config");
}
__name(getR6Config, "getR6Config");

// src/api/getR6Leaderboard.ts
async function getR6Leaderboard(params, query) {
  const { platform, mode } = params;
  return API_INSTANCE.get(`/leaderboards/${platform}/${mode}`, {
    params: query
  });
}
__name(getR6Leaderboard, "getR6Leaderboard");

// src/api/getR6Profile.ts
async function getR6Profile(profileId) {
  return API_INSTANCE.get(`/profiles/${profileId}`);
}
__name(getR6Profile, "getR6Profile");

// src/api/getR6TopPlayers.ts
async function getR6TopPlayers() {
  return API_INSTANCE.get(`/leaderboards/top`);
}
__name(getR6TopPlayers, "getR6TopPlayers");

// src/api/searchR6Market.ts
async function searchR6Market(query) {
  if (query.tags) {
    query.tags = query.tags?.join(",");
  }
  return API_INSTANCE.get(`/market/search`, {
    params: JSON.parse(JSON.stringify(query))
  });
}
__name(searchR6Market, "searchR6Market");

// src/api/searchR6Profiles.ts
async function searchR6Profiles(query) {
  return API_INSTANCE.get("/search", {
    params: query
  });
}
__name(searchR6Profiles, "searchR6Profiles");

// src/api/updateR6Profile.ts
async function updateR6Profile(profileId) {
  return API_INSTANCE.post(`/profiles/${profileId}/update`);
}
__name(updateR6Profile, "updateR6Profile");

// src/api/getR6MarketItem.ts
async function getR6MarketItem(itemId) {
  return API_INSTANCE.get(`/market/items/${itemId}`);
}
__name(getR6MarketItem, "getR6MarketItem");

// src/types/queries.types.ts
var MARKET_SEARCH_QUERY_SORT_OPTIONS = [
  "averagePrice",
  "priceDelta",
  "display"
];

// src/math/safeDivide/safeDivide.ts
function safeDivide(x, y) {
  if (y === 0) {
    return x;
  }
  return x / y;
}
__name(safeDivide, "safeDivide");

// src/math/calcDecimal/calcDecimal.ts
function calcDecimal(x, y, precision) {
  return parseFloat(safeDivide(x, y).toFixed(precision));
}
__name(calcDecimal, "calcDecimal");

// src/math/calcArrayAverage/calcAverage.ts
function calcAverage(data, precision = 2) {
  const nonNullData = data.filter((n) => n !== null);
  const total = nonNullData.reduce((acc, c) => {
    return acc + c;
  }, 0);
  if (nonNullData.length) {
    return calcDecimal(total, nonNullData.length, precision);
  } else {
    return null;
  }
}
__name(calcAverage, "calcAverage");

// src/math/roundNumber/roundNumber.ts
function roundNumber(value, precision = 0) {
  const multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}
__name(roundNumber, "roundNumber");

// src/math/decimalToPercentage/decimalToPercentage.ts
function decimalToPercentage(decimal, precision = 0) {
  return roundNumber(decimal * 100, precision);
}
__name(decimalToPercentage, "decimalToPercentage");

// src/math/calcPercentage/calcPercentage.ts
function calcPercentage(x, y, precision) {
  if (y === 0) {
    y = 1;
  }
  return decimalToPercentage(safeDivide(x, y), precision);
}
__name(calcPercentage, "calcPercentage");

// src/math/numberToCompactNotation/numberToCompactNotation.ts
function numberToCompactNotation(value, decimals = 1) {
  return Intl.NumberFormat("en-US", {
    notation: "compact",
    maximumFractionDigits: decimals
  }).format(value);
}
__name(numberToCompactNotation, "numberToCompactNotation");

// src/math/calcKd/calcKd.ts
function calcKd(kills, deaths, precision = 2) {
  return safeDivide(kills, deaths).toFixed(precision);
}
__name(calcKd, "calcKd");

// src/utils/getBanReasonBySlug.ts
var BAN_REASONS = {
  battleye: "BattlEye",
  boosted: "Boosted",
  botting: "Botting",
  cheating: "Cheating",
  ddos: "DDoS",
  "tos-breach": "TOS Breach",
  "toxic-behavior": "Toxic Behavior"
};
var BAN_REASON_SLUGS = Object.keys(BAN_REASONS);
var BAN_REASON_REASONS = Object.values(BAN_REASONS);
function getBanReasonBySlug(reason) {
  return BAN_REASONS[reason];
}
__name(getBanReasonBySlug, "getBanReasonBySlug");
export {
  BAN_REASONS,
  BAN_REASON_REASONS,
  BAN_REASON_SLUGS,
  MARKET_SEARCH_QUERY_SORT_OPTIONS,
  calcAverage,
  calcDecimal,
  calcKd,
  calcPercentage,
  decimalToPercentage,
  getBanReasonBySlug,
  getR6BansData,
  getR6Config,
  getR6Leaderboard,
  getR6MarketItem,
  getR6Profile,
  getR6TopPlayers,
  numberToCompactNotation,
  roundNumber,
  safeDivide,
  searchR6Market,
  searchR6Profiles,
  setR6BaseUrl,
  updateR6Profile
};
