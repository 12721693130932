<script setup lang="ts">
const props = defineProps<{ error: any }>();

const handleError = () => clearError({ redirect: "/" });
watchEffect(() => console.log(props.error));

const stack = props.error.stack.replace(
	/<span class="stack">[^<]*?([^\\]+\.(vue|ts|js|html):\d+:\d+)[^<]*<\/span>/g,
	'<span class="stack">$1</span>',
);
</script>

<template>
	
			<div class="flex h-screen flex-col items-center justify-center gap-2 text-white">
				<span>Wah wah an error happened</span>
				<button class="bg-base-500 hover:bg-base-400 rounded-lg px-4 py-2 text-sm" @click="handleError">
					Home
				</button>
			</div>
		
</template>
