<script setup lang="ts">
import { setR6BaseUrl } from "@secret-stats/r6-api";

const config = useRuntimeConfig();
setR6BaseUrl(config.public.backendDomain);

useSeoMeta({
	robots: {
		index: false,
		follow: false,
	},
});

await fetchSiegeConfig();
//
</script>

<template>
	<div class="relative flex min-h-screen flex-col">
		<Head>
			<Meta name="darkreader-lock" content="" />
			<Link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
			<Link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
			<Link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
			<Link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
		</Head>
		<TooltipProvider disableClosingTrigger :delayDuration="100">
			<AppNav />
			<NuxtLoadingIndicator
				color="repeating-linear-gradient(to right, var(--hsl-primary-100) 0%,var(--hsl-primary-500) 50%,var(--hsl-primary-900) 100%)"
			/>
			<div class="container flex w-full max-w-screen-2xl flex-1 px-10 py-6">
				<RouterView />
			</div>
		</TooltipProvider>
	</div>
</template>

<style>
body {
	font-family: "Roboto";
	@apply bg-base-700 overflow-x-hidden overflow-y-scroll text-sm text-white sm:text-base;
}

input,
button {
	@apply focus:outline-none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type="number"] {
	-moz-appearance: textfield;
}

::-webkit-scrollbar {
	width: 8px;
	@apply md:w-[14px];
}

::-webkit-scrollbar-track {
	@apply bg-base-500;
}

::-webkit-scrollbar-thumb {
	@apply bg-base-700;
}

::-webkit-scrollbar-thumb:hover {
	@apply bg-base-900;
}

button {
	@apply outline-none focus:outline-none;
}

* {
	-webkit-tap-highlight-color: #4c4c4c11;
	-ms-touch-action: manipulation;
	touch-action: manipulation;
	scrollbar-width: thin;
}
</style>
